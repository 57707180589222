<template>
  <div class="layout-container">
    <!-- <nav-bar
      :title="navbartitle"
      :backColor="user ? '#ff0000' : '#ff0000'"
      v-show="navbartitle && this.tabbarURL.includes(this.$route.path)"
    ></nav-bar> -->
    <!-- 路由占位符 -->
    <div class="content">
      <router-view></router-view>
    </div>
    <!-- 底部标签区域 -->
    <van-tabbar
      class="layout-tabbar"
      v-model="active"
      route
      @change="onChange"
      active-color="#ee0a24"
      inactive-color="#666"
    >
      <van-tabbar-item
        :to="item.path"
        v-for="(item, index) in tabbars"
        :key="index"
        :badge="item.path === '/ChatList' ? $store.state.unreadMessage : ''"
      >
        <template #icon="props">
          <img :src="props.active ? item.active : item.pic" />
        </template>
        <i
          slot="icon"
          class="iconfont"
          v-if="!!item.icon"
          :class="item.icon"
          v-show="!user"
        ></i>
        <span>{{ item.title }}</span>
      </van-tabbar-item>
      <!-- <van-tabbar-item to="/lottery" v-if="user"> -->

      <!-- to="/user/message"  -->
      <!-- to="/ChatList" -->
      <!-- <van-tabbar-item v-if="!isLinkGame" to="/ChatList">
        <template #icon="props">
          <img :src="props.active ? icon[1].active : icon[1].inactive" />
        </template>
        <i slot="icon" class="iconfont icon-jiangbei"></i>
        <span>phòng chat</span>
      </van-tabbar-item> -->
      <!-- <van-tabbar-item to="/lottery" v-if="isLinkGame">
        <template #icon="props">
          <img :src="props.active ? icon[1].active : icon[1].inactive" />
        </template>
        <i slot="icon" class="iconfont icon-jiangbei"></i>
        <span>Mở thưởng</span>
      </van-tabbar-item> -->

      <!-- <van-tabbar-item to="/brand" v-else>
        <template #icon="props">
          <i
            slot="icon"
            class="iconfont"
            :class="props.active ? 'icon-brandfill' : 'icon-brand'"
          ></i>
        </template>
        <span>品牌</span>
      </van-tabbar-item> -->

      <!-- <template v-if="verConfig.hasDiscover"> -->
      <!-- 游戏列表 -->
      <!-- <van-tabbar-item to="/discover">
          <template #icon="props">
            <img :src="props.active ? icon[2].active : icon[2].inactive" />
          </template>
          <i slot="icon" class="iconfont icon-jia" v-show="!user"></i>
          <span>Chia sẻ</span>
        </van-tabbar-item> -->
      <!-- 抽奖 -->
      <!-- <van-tabbar-item to="/Draw">
          <template #icon="props">
            <img :src="props.active ? icon[1].active : icon[1].inactive" />
          </template>
          <i slot="icon" class="iconfont icon-jia" v-show="!user"></i>
          <span>bốc thưởng</span>
        </van-tabbar-item>
      </template> -->
      <!-- 抽奖 -->
      <!-- <van-tabbar-item to="/Draw" v-else>
          <template #icon="props">
            <img :src="props.active ? icon[2].active : icon[2].inactive" />
          </template>
          <i slot="icon" class="iconfont icon-jia" v-show="!user"></i>
          <span>Chia sẻ</span>
        </van-tabbar-item>
      <van-tabbar-item to="/discounts">
        <template #icon="props">
          <img :src="props.active ? icon[3].active : icon[3].inactive" />
        </template>
        <i slot="icon" class="iconfont icon-biaoqian1" v-show="!user"></i>
        <span>Khuyến mãi</span>
      </van-tabbar-item>
      <van-tabbar-item to="/my">
        <template #icon="props">
          <img :src="props.active ? icon[4].active : icon[4].inactive" />
        </template>
        <i slot="icon" class="iconfont icon-ren" v-show="!user"></i>
        <span>Tôi</span>
      </van-tabbar-item> -->
    </van-tabbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import red1 from '@/assets/images/login-tabar/label_red_1.png'
import black1 from '@/assets/images/login-tabar/label_black_1.png'
import red2 from '@/assets/images/login-tabar/label_red_2.png'
import black2 from '@/assets/images/login-tabar/label_black_2.png'
import red22 from '@/assets/images/login-tabar/chat-select.png'
import black22 from '@/assets/images/login-tabar/chat.png'
import { userinfo } from '@/api/use.js'
import red4 from '@/assets/images/login-tabar/label_red_4.png'
import black4 from '@/assets/images/login-tabar/label_black_4.png'
import red5 from '@/assets/images/login-tabar/label_red_5.png'
import black5 from '@/assets/images/login-tabar/label_black_5.png'
import red6 from '@/assets/images/login-tabar/label_red_6.png'
import black6 from '@/assets/images/login-tabar/label_black_6.png'
import { isLinkGame } from '@/utils/request'
// 导入导航栏组件
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'LayoutIndex',
  components: {
    'nav-bar': Navbar
  },
  data () {
    this.hasDiscoverTabbarData = [
      {
        path: '/home',
        pic: require('@/assets/images/login-tabar/label_black_1.png'),
        active: require('@/assets/images/login-tabar/label_red_1.png'),
        title: 'Trang chủ'
        // icon: 'icon-home'
      },
      // {
      //   path: '/lottery',
      //   pic: require('@/assets/images/login-tabar/chat.png'),
      //   active: require('@/assets/images/login-tabar/chat-select.png'),
      //   title: 'Mở thưởng'
      // },
      {
        path: '/discover',
        pic: require('@/assets/images/login-tabar/label_black_4.png'),
        active: require('@/assets/images/login-tabar/label_red_4.png'),
        title: 'Chia sẻ'
      },
      // {
      //   path: '/Draw',
      //   pic: require('@/assets/images/login-tabar/draw.png'),
      //   active: require('@/assets/images/login-tabar/draw_active.png'),
      //   title: 'bốc thưởng'
      // },
      {
        path: '/discounts',
        pic: require('@/assets/images/login-tabar/label_black_5.png'),
        active: require('@/assets/images/login-tabar/label_red_5.png'),
        title: 'Khuyến mãi'
      },
      {
        path: '/TreasureChest',
        pic: require('@/assets/images/login-tabar/draw.png'),
        active: require('@/assets/images/login-tabar/draw_active.png'),
        title: 'Rương kho báu'
      },
      {
        path: '/my',
        pic: require('@/assets/images/login-tabar/label_black_6.png'),
        active: require('@/assets/images/login-tabar/label_red_6.png'),
        title: 'Tôi'
      }
    ]
    this.notDiscoverTabbarData = [
      {
        path: '/home',
        pic: require('@/assets/images/login-tabar/label_black_1.png'),
        active: require('@/assets/images/login-tabar/label_red_1.png'),
        title: 'Trang chủ'
        // icon: 'icon-home'
      },
      {
        path: '/ChatList',
        pic: require('@/assets/images/login-tabar/chat.png'),
        active: require('@/assets/images/login-tabar/chat-select.png'),
        title: 'phòng chat'
        // icon: 'icon-home'
      },
      {
        path: '/Draw',
        pic: require('@/assets/images/login-tabar/label_black_4.png'),
        active: require('@/assets/images/login-tabar/label_red_4.png'),
        title: 'Chia sẻ'
      },
      {
        path: '/discounts',
        pic: require('@/assets/images/login-tabar/label_black_5.png'),
        active: require('@/assets/images/login-tabar/label_red_5.png'),
        title: 'Khuyến mãi'
      },
      {
        path: '/TreasureChest',
        pic: require('@/assets/images/login-tabar/label_black_5.png'),
        active: require('@/assets/images/login-tabar/label_red_5.png'),
        title: 'Rương kho báu'
      },
      {
        path: '/my',
        pic: require('@/assets/images/login-tabar/label_black_6.png'),
        active: require('@/assets/images/login-tabar/label_red_6.png'),
        title: 'Tôi'
      }
    ]
    return {
      isLinkGame: isLinkGame,
      // 标签选中项
      active: 0,
      // 导航栏的标题
      navbartitle: '',
      userInfo: {},
      icon: [
        {
          active: red1,
          inactive: black1
        },
        {
          active: isLinkGame ? red2 : red22,
          inactive: isLinkGame ? black2 : black22
        },
        {
          active: red4,
          inactive: black4
        },
        {
          active: red5,
          inactive: black5
        },
        {
          active: red6,
          inactive: black6
        }
      ],
      selectTabbar: {
        0: '',
        // 1: ["彩票号码", "品牌"],
        1: ['Mở thưởng xổ số', 'thương hiệu'],
        2: 'Chia sẻ',
        3: 'Khuyến mãi',
        4: ''
      },
      //   tabbarURL: ["/brand", "/discover", "/discounts", "/my", "/lottery"],
      tabbarURL: ['/brand', '/discounts', '/my', '/lottery'],
      changeNavTitle: {
        LOTTERY: 'Mở thưởng xổ số',
        DISCOVER: 'Chia sẻ',
        // MY: this.$route.path === '/discover' || this.user ? '' : 'đăng nhậpสมาชิก',
        MY: this.$route.path === '/discover' || this.user ? '' : ''
      },
      selecttab: [
        { isshowred: false },
        { isshowred: false },
        { isshowred: false },
        { isshowred: false },
        { isshowred: false }
      ],
      tabbars: []
    }
  },
  mounted () {},
  created () {
    if (this.isLinkGame) {
    }
    const { changeNavTitle } = this
    var changeTitle = this.$route.path.substr(1).toUpperCase()
    if (this.user && changeTitle === 'MY') {
      this.navbartitle = ''
    } else {
      this.navbartitle = !Object.keys(changeNavTitle).includes(changeTitle)
        ? changeTitle === 'DISCOUNTS'
          ? 'Khuyến mãi'
          : changeTitle
        : changeNavTitle[changeTitle]
    }
    if (this.verConfig.hasDiscover) {
      this.tabbars = this.hasDiscoverTabbarData
    } else {
      this.tabbars = this.notDiscoverTabbarData
    }
    if (this.isLinkGame) {
      this.tabbars.splice(1, 0, {
        path: '/lottery',
        pic: require('@/assets/images/login-tabar/label_black_2.png'),
        active: require('@/assets/images/login-tabar/label_red_2.png'),
        title: 'Mở thưởng'
      })
    }
  },
  methods: {
    onChange (index) {
      const { selectTabbar } = this
      if (index === 1) {
        this.navbartitle = this.user
          ? selectTabbar[index][0]
          : selectTabbar[index][1]
      } else if (index === 3) {
        this.navbartitle = selectTabbar[index]
      } else {
        this.navbartitle = selectTabbar[index]
      }
    },
    async getUserInfo () {
      const {
        data: { ret, data }
      } = await userinfo()
      if (ret === 1) {
        this.userInfo = data
      }
    },
    //
    goMessage () {
      this.getUserInfo()
      if (this.userInfo == null) {
        this.$toast('Vui lòng đăng nhập trước!')
      } else {
        const disableMessage = this.userInfo.disable_message
        // const disableMessage = 2
        console.log('disableMessage:' + disableMessage)
        if (disableMessage === 1 || 0) {
          console.log('可以进')
          this.$router.push('/user/message')
        } else {
          this.$toast('Chỉ có người quản lý mời mới được phép vào！')
          setInterval(() => {
            this.$toast.clear()
          }, 3000)
        }
      }
    }
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>

<style lang="less" scoped>
.layout-container {
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
  .content{
    padding-bottom: 100px;
  }
  .layout-tabbar {
    .van-tabbar-item {
      box-sizing: border-box;

      /deep/ .van-tabbar-item__icon {
        margin-bottom: 0;
      }

      .img {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
      }

      i.iconfont {
        font-size: 40px;
      }

      span {
        display: block;
        margin-top: 15px;
        font-size: 20px;
        text-align: center;
      }
    }
  }
}
</style>
